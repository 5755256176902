<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Contact us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">Customer Service</h2>
          <p>Get in touch with our customer service team</p>
          <ul>
            <li><a href="https://info.upstreamonline.com/ups-customer-service-form">Contact us</a></li>
            <li>
              <div>Call Us:</div>
              <div class="pl-4">London: +44 207 645 2300 ,<br> Oslo: +47 2200 1200 ,<br> Stamford: +1 203 324 2994 ,<br> Singapore: +65 3165 0940</div>
            </li>
          </ul>

          <h2>Senior team</h2>

          <p>
            Editor-In-Chief: <a href="mailto:leia.parker@Upstreamonline.com">Leia Parker</a><br>
            Online Editor: <a href="mailto:eoin.ocinneide@upstreamonline.com">Eoin O’Cinneide</a><br>
            Global Sales Director - Marketing Solutions: <a href="mailto:Pallavi.Pandey@upstreamonline.com">Pallavi Pandey</a>
            <br>
          </p>

          <h2>Head Office</h2>

          <p>
            <strong>Oslo</strong><br>
            PO Box 1182 Sentrum, N-0107 Oslo, Norway<br>
            Tel.: +47 2200-1300
            <br>
          </p>

          <h2>Main Bureaux</h2>

          <p>
            <strong>London</strong><br>
            125 Wood Street, 7th Floor, London, EC2V 7AN, United Kingdom<br>
            Tel.: +44 207 645 2329
            <br>
          </p>

          <p>
            <strong>Houston</strong><br>
            Two Memorial Plaza, 820 Gessner Suite 775, Houston TX. 77024 USA<br>
            Tel.: +1 713 626 3113
            <br>
          </p>

          <p>
            <strong>Singapore</strong><br>
            The Riverwalk #04-04, 20 Upper Circular Road, Singapore 058416<br>
            Tel.: +65 3165 0960
            <br>
          </p>

          <h2>Branch Offices (Editorial Only)</h2>

          <p>
            <strong>Moscow</strong><br>
            Tel.: +7 926 203 2233
            <br>
          </p>

          <p>
            <strong>New Delhi</strong><br>
            Tel.: +91 981 085 9920
            <br>
          </p>

          <p>
            <strong>Perth</strong><br>
            Tel.: +61 412 577 266
            <br>
          </p>

          <p>
            <strong>Rio De Janeiro</strong><br>
            Tel.: +55 21 98848 9410
            <br>
          </p>

          <p>
            <strong>Wellington</strong><br>
            Tel.: +64 4 976 9572
            <br>
          </p>

          <h2>Subscriptions and Advertisements</h2>

          <p>
            <strong>Oslo</strong><br>
            PO Box 1182 Sentrum, N-0107 Oslo, Norway<br>
            Tel.: +47 2200 1300
            <br>
          </p>

          <p>
            <strong>London</strong><br>
            Tel.: +44 207 645 2300
            <br>
          </p>

          <p>
            <strong>Stamford</strong><br>
            1010 Washington Blvd, 2nd Floor, Stamford CT 06901<br>
            Tel.: +1 203 324 2994
            <br>
          </p>

          <h2>Our privacy policy</h2>

          <p>
            <a href="https://www.upstreamonline.com/myup/faq/">For more information on our privacy policy click here</a><br><br>
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'contactus-page',
  extends: Page
};
</script>
